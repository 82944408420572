import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import fileContext from "../../context/fileContext";
import FileView from "../FileView";

export default function OpenFiles({ isOpen, closeDialog }) {
  const context = React.useContext(fileContext);

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={closeDialog}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "95vw",
            height: "95vh",
            borderRadius: "md",
            boxShadow: "lg",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1.5rem",
          }}
        >
          <ModalClose variant="solid" sx={{ m: -2 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {context.selectedFiles.map((file) => {
              const fileInfo = {
                name: file.name,
                size: file.size,
                id: file.id,
                owner: file.owner,
                ownerProfileUrl: file?.profilePic,
                lastUpdate: file?.lastUpdate,
                mimetype: file?.mimetype,
                download_url: file?.downloadUrl,
              };

              return (
                <div className="h-full">
                  <FileView fileInfo={fileInfo} />
                </div>
              );
            })}
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
