import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SecurityAllocation from "../SecurityAllocation";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { auth } from "../../helper/firebaseClient";
import { api } from "../../utils/axios-instance";
import CircularProgress from "@mui/material/CircularProgress";
import fileContext from "../../context/fileContext";

const MultiFileShare = ({ isOpen, closeMenu }) => {
  const [checkboxValues, setCheckboxValues] = useState({
    geoLocation: false,
    uniqueIdentifiers: false,
    accessControl: false,
  });
  const [securityAllotmentData, setSecurityAllotmentData] = useState("");
  const [recieverEmail, setRecieverEmail] = useState("");
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const context = useContext(fileContext);
  const { selectedFiles } = context;

  const closeDialog = () => {
    closeMenu();
  };

  const handleCheckboxChange = (checkbox) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [checkbox]: !prevValues[checkbox],
    }));
  };

  function handleSecurityAllocation(data) {
    setSecurityAllotmentData(data);
    console.log("handleSecurityAllocation", data);
  }

  const shareFile = async (file) => {
    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken();

      if (recieverEmail.trim() !== "") {
        console.log("recieverEmail", recieverEmail);
        const res = await api.post(`/file/shareAFileThroughEmail`, {
          fileId: file.id,
          to: recieverEmail,
          idToken: token,
          message: message,
        });

        console.log("shareFiles:", res);

        showSnackbar("Files shared successfully", "success");

        setTimeout(() => {
          closeDialog();
          handleSnackbarClose();
        }, 2000);
      }
      if (securityAllotmentData.selectedUsers.length > 0) {
        const selectedUserIds = securityAllotmentData.selectedUsers.map(
          (user) => user.id
        );

        const res = await api.post(`/file/shareFile`, {
          file: [file.id],
          shared_with: selectedUserIds,
          expiration_time: securityAllotmentData.timeDifference
            ? securityAllotmentData.timeDifference
            : 31536000,
          expiresOn: securityAllotmentData.expiresOn,
          security_details: {
            download_enabled: true,
            geo_enabled: securityAllotmentData.location,
            selective_access: securityAllotmentData.selectiveAcessData,
          },
          idToken: token,
        });

        console.log("shareFiles:", res);

        // Show snackbar on successful file sharing
        showSnackbar("File shared successfully", "success");

        setTimeout(() => {
          closeDialog();
        }, 2000);
      }
    } catch (error) {
      console.log("error occurred while setting the permissions", error);
      // Show snackbar on error
      if (error.response.status === 406) {
        showSnackbar("File already shared", "error");
      } else {
        showSnackbar(error.error || "Error sharing file", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClick = () => {
    for (const file of selectedFiles) {
      shareFile(file);
    }
  };

  return (
    <div className="w-full">
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        <DialogTitle>Share Files</DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          <div className="my-2 w-[486px] p-3 overflow-x-hidden">
            <div>
              <p className="text-gray-600 font-semibold my-1">
                Security Features
              </p>
              <span className="grid grid-cols-2 text-gray-800 font-semibold">
                <label>
                  <input
                    type="checkbox"
                    checked={checkboxValues.geoLocation}
                    className="mx-1"
                    onChange={() => handleCheckboxChange("geoLocation")}
                  />
                  Geo - Location
                </label>

                <label>
                  <input
                    type="checkbox"
                    checked={checkboxValues.accessControl}
                    className="mx-1"
                    onChange={() => handleCheckboxChange("accessControl")}
                  />
                  Timeframe
                </label>
              </span>
            </div>

            <SecurityAllocation
              handleSecurityAllocation={handleSecurityAllocation}
              isOpen={isOpen}
              checkboxValues={checkboxValues}
              recieverEmail={recieverEmail}
              setRecieverEmail={setRecieverEmail}
              message={message}
              setMessage={setMessage}
            />

            {snackbarOpen && (
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
              >
                <MuiAlert
                  onClose={handleSnackbarClose}
                  severity={snackbarSeverity}
                  sx={{ width: "100%" }}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <button
            className="px-2 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
            onClick={closeDialog}
            color="primary"
          >
            Close
          </button>
          <button
            className={`flex items-center gap-2 disabled:opacity-60 disabled:cursor-not-allowed px-4 py-1 rounded-lg shadow-sm text-white bg-[#5E5ADB] hover:bg-[#6e6bd0da]`}
            onClick={handleClick}
            disabled={loading}
          >
            Share
            {loading && <CircularProgress size={20} color="inherit" />}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MultiFileShare;
