import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { api } from "../../utils/axios-instance";
import { auth } from "../../helper/firebaseClient";
import toast from "react-hot-toast";
import fileContext from "../../context/fileContext";

export function SetPassword({ file }) {
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState("");
  const context = useContext(fileContext);
  const { updateFilesState } = context;

  const renameFileMutation = useMutation({
    mutationKey: ["protectFile", file.id],
    mutationFn: async () => {
      if (password.trim() === "") {
        throw new Error("Please enter a valid password");
      }

      const token = await auth.currentUser.getIdToken();

      const res = await api.post("/file/protect_file/", {
        fileId: file.id,
        password: password,
        idToken: token,
      });

      toast.success(res.data.message);

      return res.data;
    },
    onError: (err) => {
      console.log("Error in setPassword", err);
      setPassword("");
      toast.error(err?.response?.data?.message || err?.message);
    },
    onSuccess: async () => {
      setIsOpen(false);
      await updateFilesState();
    },
  });

  return (
    <div>
      <button onClick={() => setIsOpen(true)}>Set Password</button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        <DialogTitle>
          {file.hasPassword ? "Reset Password" : "Set Password"}
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          {/* {JSON.stringify(file)} */}
          <Input
            autoFocus
            id="password"
            name="password"
            type="text"
            value={password}
            placeholder="Enter password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <button
            className="px-2 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
            onClick={() => {
              setPassword("");
              setIsOpen(false);
            }}
            color="primary"
          >
            Close
          </button>
          <button
            className={`flex items-center gap-2 disabled:opacity-60 disabled:cursor-not-allowed px-4 py-1 rounded-lg shadow-sm text-white bg-[#5E5ADB] hover:bg-[#6e6bd0da]`}
            onClick={() => renameFileMutation.mutate()}
            disabled={renameFileMutation.isPending}
          >
            Confirm
            {renameFileMutation.isPending && (
              <CircularProgress size={20} color="inherit" />
            )}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
