import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useContext, useState } from "react";
import { api } from "../../utils/axios-instance";
import { auth, storage } from "../../helper/firebaseClient";
import toast, { Toaster } from "react-hot-toast";
import fileContext from "../../context/fileContext";
import { v4 as uuidv4 } from "uuid";
import { deleteObject, ref } from "firebase/storage";
import useAuth from "../../stores/authStore";
import CloseIcon from "@mui/icons-material/Close";

export function ShowVersions({ file, openFile, closeMenu }) {
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(fileContext);
  const { updateFilesState, setFilteredData } = context;
  const CACHE_NAME = "blob-cache";
  const profileData = useAuth((state) => state.profileData);

  const restorePreviousVersion = async (id) => {
    const token = await auth.currentUser.getIdToken();
    const newId = uuidv4();

    const res = await api.post(`file/restore-file-version/${file.id}/`, {
      idToken: token,
      srcVersion: id,
      versionId: newId,
    });

    // If the file is not saved as a version, then we can delete this copy from our storage
    if (!file.versions.some((obj) => obj.id === file.currVersion)) {
      const fileRef = ref(
        storage,
        `files/${profileData.org}/${file.currVersion}`
      );

      // Delete the file
      deleteObject(fileRef)
        .then(() => {
          // File deleted successfully
          console.log("Deleted File Copy");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    }

    setFilteredData((prevData) =>
      prevData.map((data) =>
        data.id === file.id
          ? {
              ...data,
              currVersion: newId,
            }
          : data
      )
    );

    removeFileFromCache(token); // Remove the file from cache

    openFile(newId);
    setIsOpen(false);
  };

  const removeFileFromCache = async (token) => {
    try {
      const fileIdentifier = `${file.id}-${token}`;
      const cache = await caches.open(CACHE_NAME);
      const success = await cache.delete(fileIdentifier); // Delete the file

      if (success) {
        console.log("File removed from cache:", fileIdentifier);
      } else {
        console.log("File not found in cache:", fileIdentifier);
      }
    } catch (error) {
      console.error("Error while removing file from cache:", error);
    }
  };

  return (
    <div>
      <Toaster reverseOrder={false} position="bottom-left" />
      <button onClick={() => setIsOpen(true)}>Show Versions</button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "15px",
            maxHeight: "78%",
            width: "50%",
            padding: "0px",
          },
        }}
        maxWidth="lg"
      >
        <DialogContent
          style={{
            backgroundColor: "white",
            position: "relative",
            padding: "0px",
          }}
        >
          <div className="flex flex-col">
            <div className="flex justify-between items-center p-4">
              <div>
                <h3 className="text-lg font-bold">File Versions</h3>
                <p className="text-base">Track your file versions here</p>
              </div>
              <div className="p-1 bg-[#F2786B] rounded-full hover:cursor-pointer">
                <CloseIcon
                  sx={{ color: "white" }}
                  onClick={() => {
                    setIsOpen(false);
                    closeMenu();
                  }}
                />
              </div>
            </div>
            <div
              style={{ borderBottom: "0.25px solid grey" }}
              className="w-full"
            ></div>
            {file?.versions?.map((version, index) => {
              return (
                <div className="flex items-center group relative pl-6 h-14">
                  <p>{version.lastUpdate}</p>
                  <div
                    style={{ borderLeft: "0.25px solid grey" }}
                    className="h-full mx-10"
                  ></div>
                  <h2 className="text-lg italic font-semibold w-[20%]">{`Version ${index + 1}`}</h2>

                  <button
                    className="px-4 py-1 rounded-md border bg-[#1c4ed8] text-white absolute right-10 invisible group-hover:visible"
                    onClick={() => restorePreviousVersion(version.id)}
                  >
                    Restore
                  </button>
                </div>
              );
            })}
          </div>
        </DialogContent>
        {/* <DialogActions sx={{ padding: "10px" }}>
          <button
            className="px-2 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
            onClick={() => {
              setIsOpen(false);
            }}
            color="primary"
          >
            Close
          </button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
