import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import fileContext from "../../context/fileContext";
import secureLocalStorage from "react-secure-storage";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { api } from "../../utils/axios-instance";
import { useParams } from "react-router-dom";
import { auth } from "../../helper/firebaseClient";

// images from public folder
const Danger = `${process.env.PUBLIC_URL}/assets/danger.webp`;

function RecycleMultiFilesConfirmation({
  isOpen,
  closeDialog,
  closeDeleteDialog,
  removeMultiSelect,
  removeFiles,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const context = useContext(fileContext);
  const { updateDepartmentFiles } = context;
  const { deptName } = useParams();

  const handleRecycle = async (file) => {
    let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));
    const token = await auth.currentUser.getIdToken();

    if (profileData.id === file.owner) {
      try {
        const recycleData = {
          fileId: file.id,
          fileName: file.name,
          owner: file.owner,
          deleted: 1,
          idToken: token,
          size: file.size,
          mimeType: file.mimetype,
          profilePic: file.profilePic,
          downloadUrl: file.downloadUrl,
          currVersion: file?.currVersion,
          versions: file?.versions,
        };

        console.log("File all values:", file);
        console.log("Recycling file with data:", recycleData);

        // Sending the recycle request to the backend
        const res = await api.post(
          `/file/recycle-file/${file.id}/`,
          recycleData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(
          "File after being moved to Recycle Bin via backend:",
          res.data
        );
      } catch (error) {
        if (error.response) {
          // Server responded with a status code outside the 2xx range
          console.error("Server error:", error.response.data);
          throw new Error(
            `Error occurred while deleting the file: ${error.response.data.detail || error.response.data}`
          );
        } else if (error.request) {
          // No response was received from the server
          console.error("No response received from server:", error.request);
          throw new Error("No response received from server.");
        } else {
          // Error occurred in setting up the request
          console.error("Request setup error:", error.message);
          throw new Error(
            "Error occurred while setting up the request: " + error.message
          );
        }
      }
    } else {
      throw new Error("You are not the owner of the file.");
    }
  };

  const recycleFiles = async () => {
    try {
      const recyclePromises = context.selectedFiles.map((file) => {
        console.log("File: ", file);
        handleRecycle(file);
      });
      await Promise.all(recyclePromises);

      setSnackbarSeverity("success");
      setSnackbarMessage("Files deleted successfully.");
      setSnackbarOpen(true);

      //   const newFiles = context.filteredData.filter(
      //     (file) => !context.selectedFiles.includes(file)
      //   );
      //   context.setFilteredData(newFiles);

      //   if (removeFiles) removeFiles();

      setTimeout(() => {
        closeDialog();
        closeDeleteDialog();
        removeMultiSelect();
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error(error);
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message || "Error deleting files.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="">
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        <DialogContent
          style={{
            backgroundColor: "#FEF2F2",
          }}
        >
          <div className="my-2 p-3 flex flex-col justify-center items-center gap-6">
            <img src={Danger} alt="." />
            <h2 className="text-2xl font-semibold text-gray-700">
              Are you sure?
            </h2>
            <div className="text-center">
              <p>This action will delete the following files</p>
              <ul className="my-8">
                {context.selectedFiles.map((file) => (
                  <li key={file.id} className="font-bold">
                    {file.name}
                  </li>
                ))}
              </ul>
              <p>Your Files will be present in Recycle Bin </p>
            </div>

            <div className="flex flex-row justify-center items-center gap-2">
              <button
                className="px-4 py-1 rounded-lg shadow-sm bg-red-500 text-white"
                onClick={recycleFiles}
              >
                Confirm
              </button>
              <button
                className="px-4 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
                onClick={() => {
                  closeDeleteDialog();
                  removeMultiSelect();
                }}
                color="primary"
              >
                Cancel
              </button>
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RecycleMultiFilesConfirmation;
