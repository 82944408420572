import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteMultiFilesConfirmation from "./DeleteMultiFilesConfirmation";

import fileContext from "../../context/fileContext";
import RecycleMultiFilesConfirmation from "./RecycleMultiConfirmation";

function DeleteFiles({ isOpen, closeDialog, removeMultiSelect, removeFiles }) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [select, setSelect] = useState(false);
  const context = useContext(fileContext);
  const { selectedFiles } = context; // Assuming this context provides the selected files

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "5px",
            maxWidth: "80%",
            width: "auto",
            maxHeight: "80%",
          },
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="my-2 p-3 flex flex-col justify-center items-center gap-6">
            <div className="flex flex-col">
              <button
                className="py-1 px-4 rounded-md border bg-[#D1293D] text-white my-4"
                onClick={() => setSelect(true)}
              >
                Move Files to Recycle Bin
              </button>
              <button
                className="py-1 px-4 rounded-md border bg-[#D1293D] text-white"
                onClick={() => {
                  setOpenConfirmation(true);
                }}
              >
                Delete Files Permanently
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {select && (
        <RecycleMultiFilesConfirmation
          isOpen={select}
          closeDialog={() => setSelect(false)}
          closeDeleteDialog={closeDialog}
          removeMultiSelect={removeMultiSelect}
          removeFiles={removeFiles}
          selectedFiles={selectedFiles} // Pass the array of selected files
        />
      )}

      {openConfirmation && (
        <DeleteMultiFilesConfirmation
          isOpen={openConfirmation}
          closeDialog={() => setOpenConfirmation(false)}
          closeDeleteDialog={closeDialog}
          removeMultiSelect={removeMultiSelect}
          removeFiles={removeFiles}
        />
      )}
    </div>
  );
}

export default DeleteFiles;
